































































import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import { toCurrency, toDisplayName } from "@/helpers/Formatter";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
        BaseAvatar,
        BaseLink,
    },
})
export default class AdminUserListItem extends Vue {
    @Prop() plan!: any;

    get me() {
        return this.$store.state.users.me;
    }

    get isCreator() {
        if (!this.me || !this.plan) return false;
        return this.me.id === this.plan.user.id;
    }

    toDisplayName(user: any) {
        return toDisplayName(user)
    }

    goToUser(user: any) {
        let route: string = "/app/perusahaan/";
        if (user.role.name === "PROPOSER") route = "/app/pengusul/";
        return route + user.id;
    }

    toCurrency(value: any) {
        return toCurrency(value);
    }

    editPlan() {
        this.$router.push("/app/rencana/" + this.plan.id + "/edit");
    }
}
